import { Button, Drawer, Typography } from "@mui/material";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import DreamGirls from "../../Assets/png/DreamGirls.png";
import styles from "./Navbar.module.css";
import { ConnectWalletBtn } from "../Wallet/ConnectWallet";
import { useAccount } from "wagmi";
import { TOKEN } from "../../Helper/Constants";

const Navbar = () => {
  console.log(localStorage.getItem(TOKEN));

  const [open, setOpen] = React.useState(false);
  return (
    <nav className={styles.nav}>
      <div>
        <Link to="/">
          <img src={DreamGirls} alt="logo" />
        </Link>
      </div>
      <div className={styles.menu}>
        <ul>
          <Links />
        </ul>
      </div>
      <div className={styles.menuMobile}>
        <div onClick={() => setOpen(true)} className={styles.burger}>
          <span></span>
          <span></span>
        </div>
        <Drawer
          anchor={"right"}
          open={open}
          onClose={() => setOpen((open) => !open)}
        >
          <div className={styles.drawer}>
            <ul>
              <Links />
            </ul>
          </div>
        </Drawer>
      </div>
    </nav>
  );
};

export default Navbar;

const Links = () => {
  const token = localStorage.getItem('jwt');
  // const {isConnected} = useAccount()
  const navigate = useNavigate();
  const goTodashboard = () => {
    navigate("/dashboard");
  };

  return (
    <>
  {token==null && <>
   <li>
        <Link className={styles.link} to="/dreamgirl-application">
          Become a DreamGirl
        </Link>
      </li>
      <li>
        <Link className={styles.link} to="/affiliate">
          Affiliate
        </Link>
      </li>
      <li>
        <Link className={styles.link} to="/photograph">
          Photographer
        </Link>
      </li>
   </>}
      <li>
        <Link className={styles.link} to="/collections">
        Collections
        </Link>
      </li>

      <li>
        <Link className={styles.link} to="/business">
          Partner
        </Link>
      </li>

      <li>
        <Link className={styles.link} to="/team">
          Team
        </Link>
      </li>

      <li>
        <Link className={styles.link} to="/disclaimer">
          Disclaimer
        </Link>
      </li>
      {/* {!isConnected ?
        <ConnectWalletBtn/>
      : */}
      <li>
        {token == null ? (
          <Link className={styles.link} to="/signin">
            Log In
          </Link>
        ) : (
          <div onClick={() => goTodashboard()}>Dashboard</div>
        )}
      </li>
      {/* } */}
    </>
  );
};
