 export const backgroundArrayData = [
    "lemonade",
    "leprechaun",
    "magentaFade",
    "magma",
    "midnightBlue",
    "sunset",
    "purpleHaze",
    "blackGoldFractal",
    "steel",
    "grass",
    "champagne",
    "poker",
    "zebra",
    "bitcoin",
    "makeItRain",
    "matrix",
    "diamonds",
    "dice",
    "firestarter",
    "shortCircuit",
  ];
  export const zodiacArrayData = [
    "none",
    "silver",
    "neon",
    "gold"
  ]
  export const bordersArrayData = [
    "blue",
    "green",
    "red",
    "orange",
    "black",
    "silver",
    "rose",
    "gold",
    "quartz",
    "topaz",
    "amethyst",
    "turquoise",
    "ruby",
    "sapphire",
    "emerald",
    "diamond",
  ];
  export const suitsArrayData = [
    "red",
    "black",
    "emerald",
    "sapphire",
    "ruby",
    "diamond"
  ]