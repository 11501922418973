import React, { useState, useEffect } from "react";
import styles from "./PhotosList.module.css";
import Delete from "../../../Assets/svg/Delete.svg";
import Noimages from "../../../Assets/png/Noimages.png";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Dropzone from "../../Forms/DragnDrop";
import Loader from "../../Loader/Loader";
import DeleteImage from "./DeleteImage";
import { Box, Button, Grid, Typography } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
// import { Button, Container, Grid } from "@mui/material";
import ImageList from "./ImageList";
import SalesList from "../SalesList/SalesList";
import { useReferredUsers } from "../Hooks/useDashboard";



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const PhotosList = ({ profile, flex = 1 }) => {
  const id = profile?.id;
  const images = profile?.images;
  const [girlsImages, setGirlsImages] = useState([]);

  const [open, setOpen] = React.useState(false);
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [value, setValue] = useState(0);
  // const [value, setValue] = useState(0
  // //   () => {
  // //   const tabValue = parseInt(localStorage.getItem("selectedTab"));
  // //   return isNaN(tabValue) ? 0 : tabValue;
  // // }
  // );

  const handleChange = (event, newValue) => {
    setValue(newValue);
    // navigate(`/profile/${tabNames[newValue]}`);
  };

  useEffect(() => {
    if (images === null) return setGirlsImages([]);
    setGirlsImages(images);
  }, [images]);
  const referredUsers = useReferredUsers();
  return (
    <>
     <Box sx={{
        "& input":{
          color:"#000 !important"
        },
        position: "relative",
    "@media (max-width:991px)": {
      padding: "20px",
    },
    "@media (max-width:600px)": {
      padding: "10px",
      // "& .MuiTabs-scroller":{
      //   overflowX:"scroll !important"
      // }
    },
    "& .MuiButtonBase-root.Mui-selected": {
      color: "#c8c862 !important",
      textShadow: "inherit !important",
      fontSize: "18px !important",
      padding: "10px !important",
    },
    "& .MuiButtonBase-root": {
      color: "#000 !important",
      textTransform:"capitalize"
    },
    "& .MuiTabs-indicator": {
      backgroundColor: "#c8c862 !important",
    },
    "& .MuiTouchRipple-root": {
      display: "none",
    },
    // "& .MuiTabs-scroller": {
    //   overflow: "auto !important",
    // },
      }}>
        <Box>
          <Box>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
                <Tab
                  sx={{
                    fontSize: "18px !important",
                    padding: "10px !important",
                  }}
                  label={`My Photos`}
                  {...a11yProps(0)}
                />
                <Tab
                  sx={{
                    fontSize: "18px !important",
                    padding: "10px !important",
                  }}
                  label="Referred Users"
                  {...a11yProps(1)}
                />
               
                
         
            </Tabs>
          </Box>

        
            <TabPanel value={value} index={0}>
            <div style={{ flex: flex }}>
        <div className={styles.headerContainer}>
          <p className={styles.header}>{`My Photos (${
            girlsImages?.length || 0
          })`}</p>
     {profile?.status == "Incomplete" &&     <button onClick={handleClickOpen} className={styles.uploadButton} 
          // disabled={profile?.status === "Pending" ||girlsImages?.length>=4}
          >
            Upload
          </button>}
        </div>
        <div className={styles.list}>
          {girlsImages?.length === 0 && <NoImages girlsImages={girlsImages}/>}
          <Grid container spacing={2}
            // sx={{ width: "100%" }}
            // variant="quilted"
            // cols={4}
            // rowHeight={"auto"}
          >
            {girlsImages?.map((image,index) => (
             <Grid item md={3} sm={6} xs={12}>
        <ImageList image={image} key={index} setGirlsImages={setGirlsImages} profile={profile}/>
             </Grid>
            ))}
          </Grid>
        </div>
      </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
            <SalesList flex={2} referredUsers={referredUsers} display="none"/>
            </TabPanel>
     
      
        </Box>
      </Box>
      
      <UploadModal open={open} handleClose={handleClose} id={id} />
  
    </>
  );
}; 

export default PhotosList;

const NoImages = ({girlsImages}) => {
  return (
    <div className={styles.noImages} style={{height:girlsImages?.length === 0?"480px":"100%"}}>
      <img src={Noimages} alt="No Images" />
      <h3>Please click on the upload button to upload your photos.</h3>
    </div>
  );
};

const UploadModal = ({ open, handleClose, id }) => {
  const [result,setResult] = useState(null)
  const [loading, setLoading] = React.useState(false);
  const [files, setFiles] = React.useState([]);
    const [isCropped, setIsCropped] = useState(false);
    const [fileError, setFileError] = React.useState("");
  function dataURItoBlob(dataURI) {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
  
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
  
    return new Blob([ab], { type: mimeString });
  }

  // const upload = async () => {
  //   // if (files.length === 0) return setFileError("Please select a file");
  //   if (!files) {
  //     return setFileError("Please select and crop an image");
  //   }
  //   try {
  //     setLoading(true);
  //     const imagesData = new FormData();
  //     // files.forEach((file) => {
  //     //   console.log(file);
  //     //   imagesData.append("files", file);
  //     // });
  //     imagesData.append("files", dataURItoBlob(files), "cropped-image.png");
  //     imagesData.append("ref", "plugin::users-permissions.user");
  //     // imagesData.append("source", "users-permissions");
  //     imagesData.append("field", "images");
  //     imagesData.append("refId", id);
  //     const jwt = localStorage.getItem('jwt') ; 
     
  //     const response = await axios.post(
  //       `${process.env.REACT_APP_BACKEND}/upload`,
  //       imagesData,
  //       {
  //         headers: {
  //           "Authorization": `Bearer ${jwt}`,
  //           "Content-Type": "multipart/form-data",
  //         },
  //       },
  //     );

  //     if (response.status === 200) {
  //       handleClose();
  //       setLoading(false);
  //       //reload the page
  //       window.location.reload();
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  async function fetchBlobFromURL(url) {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      return blob;
    } catch (error) {
      console.error('Error fetching Blob from URL:', error);
      return null;
    }
  }
  const upload = async () => {
    // Check if files is null or undefined
    if (!files) {
      return setFileError("Please select an image");
    }
    
    try {
      setLoading(true);
      const imagesData = new FormData();
  
      // Fetch the Blob from the data URI
      const blob = await fetchBlobFromURL(files);
      if (!blob) {
        throw new Error('Failed to fetch Blob from URL');
      }
  
      // Append the Blob to FormData
      imagesData.append("files", blob, "image.png");
      imagesData.append("ref", "plugin::users-permissions.user");
      imagesData.append("field", "images");
      imagesData.append("refId", id);
      const jwt = localStorage.getItem('jwt');
  
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND}/upload`,
        imagesData,
        {
          headers: {
            "Authorization": `Bearer ${jwt}`,
            "Content-Type": "multipart/form-data",
          },
        },
      );
  
      if (response.status === 200) {
        handleClose();
        setLoading(false);
        // Reload the page
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
    }
  };
   
  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" sx={{
        // p:"14px 24px 14px"
      }}>Select photos <span style={{fontSize:"15px",fontWeight:"600"}}>(Recommended Size: 780px X 1140px)</span></DialogTitle>
      <DialogContent>
        <Dropzone isCropped={isCropped} setIsCropped={setIsCropped} error={fileError} label={false} onchange={setFiles} styles={styles} result={result} setResult={setResult}/>
      </DialogContent>
      <DialogActions>
        <button onClick={handleClose} className={styles.cancel} style={{cursor:"pointer"}}>
          Cancel
        </button>
        <button disabled={loading || result  === null} onClick={upload} className={styles.upload}>
          {loading ? <Loader /> : "Upload"}
        </button>
      </DialogActions>
    </Dialog>
  );
};
