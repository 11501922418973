import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  NativeSelect,
  OutlinedInput,

  Typography,
} from "@mui/material";
import Container from "../../Component/Dashboard/Container/Container";
import NftCollection from "./NftCollection";
import styles from "../../Component/Dashboard/Container/Container.module.css";
import Navbar from "../../Component/Navbar/Navbar";
import Footer from "../../Component/Footer/Footer";
import axios from "axios";
import Input from "../../Component/Input/Input";
import Select from "react-select";
import { Country } from "country-state-city";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Marketplace = () => {
  const [selectedCountry, setSelectedCountry] = useState();
  const countries = Country.getAllCountries();
  const [girlData, setGirlData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  // const [ethnicity, setEthnicity] = useState([]);
  const [loadedCount, setLoadedCount] = useState(8);

  const [ethnicity, setEthnicity] = React.useState("");
  const [bodyTypeValue, setBodyTypeValue] = React.useState("");
  const [hairColorValue, setHairColorValue] = React.useState("");
  const [eyeColorValue, setEyeColorValue] = React.useState("");

  const ethnicityOptions = [
    "Caucasian/White",
    "Asian",
    "Spanish/Latino",
    "Black/ African",
    "Southeast Asian / Indian",
    "Middle Eastern",
    "Mixed",
    "Native",
  ];
  const bodyType = [
    "Skinny",
    "Slim",
    "Fit & Toned",
    "Muscular",
    "Slim Thick",
    "Curves For Days",
    "BBW",
  ];
  const hairColor = [
    "Blond",
    "Brunette",
    "Black",
    "Brown",
    "Red Head",
    "Colorful AF",
    "Other",
  ];
  const eyeColor = ["Blue", "Green", "Brown", "Hazel", "Other"];

  const loadMore = () => {
    setLoadedCount((prevCount) => prevCount + 8);
  };

  useEffect(() => {
    getGirlData();
  }, []);

  const getGirlData = async () => {
    await axios({
      method: "GET",
      url: `${process.env.REACT_APP_BACKEND}/collection/all`,
      headers: {
        "Accept-Encoding": "application/json",
      },
    })
      .then((_data) => {
        // console.log(_data);
        setGirlData(_data.data.collections);
        // console.log(_data.data.collections.map((e,i)=>e.girlId?.ethnicity?.split(',')||""));
        // setEthnicity(_data.data.collections.map((e,i)=>e.girlId?.ethnicity))
      })
      .catch((err) => {
        //  throw err
        console.log(err);
      });
  };

  
  const [searchInput, setSearchInput] = useState("");
  useEffect(() => {
    if (
      !searchInput &&
      ethnicity == "" &&
      bodyTypeValue == "" &&
      eyeColorValue == "" &&
      hairColorValue == "" &&
      !selectedCountry
    ) {
      setFilteredData(girlData);
    } else {
      const filtered = girlData?.filter((girl) => {
        // console.log(girl);
        const fullName =
          `${girl.girlId.firstName} ${girl.girlId.lastName}`.toLowerCase();
        const username = girl.girlId.username.toLowerCase();
        const instagram_handle = girl.girlId.instagram_handle.toLowerCase();
        const eyeColor = girl.girlId.eyeColor;
        const hairColor = girl.girlId.hairColor;
        const ethnicityMatch = girl.girlId?.ethnicity
          ?.split(",")
          ?.some((_ethnicity) => {
            // console.log(_ethnicity.toLowerCase().includes(ethnicity));
            // console.log(_ethnicity)
            // console.log(ethnicity)
            return _ethnicity == ethnicity || ethnicity == "";
          });

        const bodyTypeMatch = girl.girlId?.bodyType
          ?.split(",")
          ?.some((bodyType) => {
            return bodyType == bodyTypeValue || bodyTypeValue == "";
          });
          const countryMatch =
          !selectedCountry?.name ||
          girl.girlId.country?.toLowerCase() === selectedCountry?.name.toLowerCase();
  
        return (
          (girl.girlId.firstName
            .toLowerCase()
            .includes(searchInput.toLowerCase()) ||
            girl.girlId.lastName
              .toLowerCase()
              .includes(searchInput.toLowerCase()) ||
            username.toLowerCase().includes(searchInput.toLowerCase()) ||
            instagram_handle
              .toLowerCase()
              .includes(searchInput.toLowerCase()) ||
            searchInput == "") &&
          ethnicityMatch &&
          bodyTypeMatch &&
          (eyeColorValue == eyeColor || eyeColorValue == "") &&
          (hairColorValue == hairColor || hairColorValue == "") &&  countryMatch
        );
      });
      setFilteredData(filtered);
    }
  }, [searchInput, ethnicity, bodyTypeValue, eyeColor, hairColor,selectedCountry]);
  

  const handleSearch = (e) => {
    setSearchInput(e.target.value);
  };
  return (
    <>
      <Navbar />

      <div className={styles.mainContent_marketplace}>
        <Grid
          container
          spacing={2}
          pb={"3rem"}
          sx={
            {
              // display: "flex",
              // alignItems:"center",
              // gap:"1rem",
              // flexWrap:"wrap"
            }
          }
        >
          <Grid
            item
            md={12}
            sm={12}
            xs={12}
            sx={{
              pl: { lg: "0 !important" },
            
            }}
          >
               <Input
              id="instagram_handle"
              name="instagram_handle"
              type="text"
              placeholder="Search collection by name/username/instagram"
              value={searchInput}
              onChange={handleSearch}
              // width={"30%"}
            />
          </Grid>
          <Grid
            item
            md={3.5}
            sm={6}
            xs={12}
            sx={{
              pl: { lg: "0 !important" },
              "& .css-13cymwt-control,.css-t3ipsp-control,.css-1dimb5e-singleValue":{
                background:"transparent !important",
                color:"#fff !important"
              },
              "& input":{
                color:"#fff !important"
              }
            }}
          >
       
              <Select
              id="country"
              name="country"
              options={countries}
              getOptionLabel={(options) => {
                return (
                  <Box>
                    {options.flag} {options.name}
                  </Box>
                );
              }}
              getOptionValue={(options) => {
                return options["isoCode"];
              }}
              value={selectedCountry}
              onChange={(item) => {
                setSelectedCountry(item);
              }}
              placeholder="Search by country..."
              isClearable
            />
          </Grid>
          <Grid item md={8.5} sm={6} xs={12}>
            <Grid container spacing={2}>
              <Grid item md={3} sm={6} xs={12}>
                <FormControl
                  sx={{
                    width: "100%",
                    borderRadius: "8px !important",
                    background: "",
                    fontSize: "16px",
                    // border: '1px solid rgb(255, 255, 255)',
                    // color: 'rgb(255, 255, 255)',
                    mr: "0",
                    "& .MuiInputBase-root::before": {
                      display: "none",
                    },
                    "& .MuiInput-root:hover::before": {
                      borderBottom: "0",
                    },
                    "& .MuiInputBase-root::after": {
                      borderBottom: "0",
                    },
                    "& select": {
                      p: "11px",
                      pl: "15px",
                      border: "1px solid #3b3b3b",
                      color: "#7c7777",

                      my: "6px",
                    },
                  }}
                >
                  <NativeSelect
                    id="parentCategory"
                    name="parentCategory"
                    value={ethnicity}
                    onChange={(e) => setEthnicity(e.target.value)}
                    sx={{ color: "#fff" }}
                  >
                    <option style={{ color: "#fff !important" }} value={""}>
                      Search by Ethnicity
                    </option>
                    {ethnicityOptions?.length > 0 &&
                      ethnicityOptions?.map((e, i) => {
                        return (
                          <option
                            style={{ color: "#fff !important" }}
                            value={e}
                            key={i}
                          >
                            {e}
                          </option>
                        );
                      })}
                  </NativeSelect>
                </FormControl>
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <FormControl
                  sx={{
                    width: "100%",
                    borderRadius: "8px !important",
                    background: "",
                    fontSize: "16px",
                    // border: '1px solid rgb(255, 255, 255)',
                    // color: 'rgb(255, 255, 255)',
                    mr: "0",
                    "& .MuiInputBase-root::before": {
                      display: "none",
                    },
                    "& .MuiInput-root:hover::before": {
                      borderBottom: "0",
                    },
                    "& .MuiInputBase-root::after": {
                      borderBottom: "0",
                    },
                    "& select": {
                      p: "11px",
                      pl: "15px",
                      border: "1px solid #3b3b3b",
                      color: "#7c7777",

                      my: "6px",
                    },
                  }}
                >
                  <NativeSelect
                    id="parentCategory"
                    name="parentCategory"
                    value={bodyTypeValue}
                    onChange={(e) => setBodyTypeValue(e.target.value)}
                    sx={{ color: "#fff" }}
                  >
                    <option style={{ color: "#fff !important" }} value={""}>
                      Search by Body Type
                    </option>
                    {bodyType?.length > 0 &&
                      bodyType?.map((e, i) => {
                        return (
                          <option
                            style={{ color: "#fff !important" }}
                            value={e}
                            key={i}
                          >
                            {e}
                          </option>
                        );
                      })}
                  </NativeSelect>
                </FormControl>
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <FormControl
                  sx={{
                    width: "100%",
                    borderRadius: "8px !important",
                    background: "",
                    fontSize: "16px",
                    // border: '1px solid rgb(255, 255, 255)',
                    // color: 'rgb(255, 255, 255)',
                    mr: "0",
                    "& .MuiInputBase-root::before": {
                      display: "none",
                    },
                    "& .MuiInput-root:hover::before": {
                      borderBottom: "0",
                    },
                    "& .MuiInputBase-root::after": {
                      borderBottom: "0",
                    },
                    "& select": {
                      p: "11px",
                      pl: "15px",
                      border: "1px solid #3b3b3b",
                      color: "#7c7777",

                      my: "6px",
                    },
                  }}
                >
                  <NativeSelect
                    id="parentCategory"
                    name="parentCategory"
                    value={hairColorValue}
                    onChange={(e) => setHairColorValue(e.target.value)}
                    sx={{ color: "#fff" }}
                  >
                    <option style={{ color: "#fff !important" }} value={""}>
                      Search by Hair Color
                    </option>
                    {hairColor?.length > 0 &&
                      hairColor?.map((e, i) => {
                        return (
                          <option
                            style={{ color: "#fff !important" }}
                            value={e}
                            key={i}
                          >
                            {e}
                          </option>
                        );
                      })}
                  </NativeSelect>
                </FormControl>
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <FormControl
                  sx={{
                    width: "100%",
                    borderRadius: "8px !important",
                    background: "",
                    fontSize: "16px",
                    // border: '1px solid rgb(255, 255, 255)',
                    // color: 'rgb(255, 255, 255)',
                    mr: "0",
                    "& .MuiInputBase-root::before": {
                      display: "none",
                    },
                    "& .MuiInput-root:hover::before": {
                      borderBottom: "0",
                    },
                    "& .MuiInputBase-root::after": {
                      borderBottom: "0",
                    },
                    "& select": {
                      p: "11px",
                      pl: "15px",
                      border: "1px solid #3b3b3b",
                      color: "#7c7777",

                      my: "6px",
                    },
                  }}
                >
                  <NativeSelect
                    id="parentCategory"
                    name="parentCategory"
                    value={eyeColorValue}
                    onChange={(e) => setEyeColorValue(e.target.value)}
                    sx={{ color: "#fff" }}
                  >
                    <option style={{ color: "#fff !important" }} value={""}>
                      Search by Eye Color
                    </option>
                    {eyeColor?.length > 0 &&
                      eyeColor?.map((e, i) => {
                        return (
                          <option
                            style={{ color: "#fff !important" }}
                            value={e}
                            key={i}
                          >
                            {e}
                          </option>
                        );
                      })}
                  </NativeSelect>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {filteredData?.length > 0 ? (
          <Grid container spacing={2}>
            <>
              <Grid container spacing={2}>
                {filteredData?.slice(0, loadedCount).map((e, index) => (
                  <Grid item lg={3} md={4} sm={6} xs={12} key={index}>
                    <NftCollection data={e} index={index} />
                  </Grid>
                ))}
              </Grid>
            </>
          </Grid>
        ) : (
          <Typography
            sx={{
              color: "#ffffffba",
              textAlign: "center",
              fontSize: "24px",
              fontWeight: "600",
              padding: "4rem 1.5rem",
              border: "2px solid #b09869",
              borderRadius: "12px",
            }}
          >
            No Collection Found.
          </Typography>
        )}
        {filteredData.length > loadedCount && (
          <Box textAlign="center" my={"2rem"}>
            <Button
              onClick={loadMore}
              sx={{
                color: "#000",
                padding: "5px 27px",
                background:
                  "linear-gradient(92.97deg,#3d2b0a 3.8%,#fcebbf 34.39%,#eec96c 57.07%,#fed87f 99.59%)",
                "&:hover": {
                  background:
                    "linear-gradient(92.97deg,#3d2b0a 3.8%,#fcebbf 34.39%,#eec96c 57.07%,#fed87f 99.59%)",
                },
                border: "2px solid #c79d54",
                textTransform: "capitalize",
                fontSize: "18px",
              }}
            >
              Load More
            </Button>
          </Box>
        )}
      </div>
      <Footer />
    </>
  );
};

export default Marketplace;
