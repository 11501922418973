import React, { useEffect, useState } from "react";
import { Box, Button, Skeleton, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Autoplay, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
// import { Pagination } from 'swiper/modules';
import GirlImages from "./GirlImages";
import axios from "axios";

const NftCollection = ({ data, index }) => {

  const [loading, setLoading] = useState(false);
  const [defaultLoader, setDefaultLoader] = useState(false);
  const [previewResponse, setPreviewResponse] = useState("");

  //   const defaultCombination = async () => {
  //     setDefaultLoader(true)
  //     try {
  //       let _data = {
  //         girlId: data?.girlId?.id,
  //         collectionId: data?.collectionid,
  //       };

  //       const res = await axios.post(
  //         `${process.env.REACT_APP_BACKEND}/get/default/combination`,
  //         _data
  //       );
  //       if (res.status === 200) {
  //         setDefaultLoader(false)
  //         setPreviewResponse(res.data.data);
  //       }
  //     } catch (err) {
  //       setDefaultLoader(false)
  //       console.log(err);
  //     }
  //   };
  //  useEffect(()=>{
  //   defaultCombination()
  //  },[data])


  const navigate = useNavigate();
  const handleNFT = (id, collectionid) => {
    navigate(`/collection/${id}/${collectionid}`);
  };
  const imageArray = [];
  // const arrayLength = 52;

  // for (let i = 0; i < arrayLength; i++) {
  //   imageArray.push(i);
  // }
  return (
    <>
      <div>
        <Box
          sx={{
            border: "1px solid #cecfd033",
            borderRadius: "12px",
            color: "#fff",
            background: "#1d1e1f",
            padding: "8px",
            transition: "all 0.15s ease-in-out 0s",
            "&:hover": {
              transform: "translateY(-3px)",
            },
          }}
        >
          <Box sx={{ p: "2px", position: "relative" }}>
            {defaultLoader || !data?.girlId ? (
              <Box
                sx={{
                  border: "1px solid transparent",
                  height: "100%",
                  "& .MuiSkeleton-root": {
                    transformOrigin: "0 0%",
                    transform: "scale(1,1)",
                  },
                }}
              >
                <Skeleton
                  component="p"
                  sx={{ background: "#ffffffb5", height: "400px" }}
                />
              </Box>
            ) : (
              // <img
              //   src={
              //     previewResponse
              //       ? `data:image/png;base64,${previewResponse}`
              //       : data?.image?.url
              //   }
              //   width={"100%"}
              // />
              <img
                src={`${process.env.REACT_APP_META_URL}/public/preview/${data?.girlId?.id}-${data?.collectionid}.png`}
                width={"100%"}
              />
            )}
          </Box>

          <Box>
            <Button
              onClick={() => handleNFT(data?.girlId?.id, data?.collectionid)}
              disableRipple
              sx={{
                width: "100%",
                my: "0.5rem",
                p: "5px !important",
                background:
                  "linear-gradient(92.97deg,#3d2b0a 3.8%,#fcebbf 34.39%,#eec96c 57.07%,#fed87f 99.59%)",
                borderRadius: "8px",
                border: "2px solid #c79d54",
                color: "#000",
              }}
            >
              Starts from $20
            </Button>
          </Box>
        </Box>
      </div>
    </>
  );
};

export default NftCollection;
